
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Search, LogOut, Menu, X, User } from 'lucide-react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { setMessages } from '../Redux/messageSlice';
import OtherUsers from './OtherUsers';
import { setAuthUser, setOtherUsers, setSelectedUser } from '../Redux/userSlice';

const Sidebar = ({setOpenSearch}) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { otherUsers, authUser } = useSelector(store => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/logout`);
      navigate('/login');
      toast.success(res.data.message);
      dispatch(setAuthUser(null));
      dispatch(setMessages(null));
      dispatch(setOtherUsers(null));
      dispatch(setSelectedUser(null));
    } catch (error) {
      console.log(error);
      toast.error('Logout failed. Please try again.');
    }
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    filterUsers(search);
  };

  const filterUsers = (searchTerm) => {
    if (searchTerm.trim() === '') {
      // If search is empty, show all users
      dispatch(setOtherUsers(otherUsers));
    } else {
      const filteredUsers = otherUsers?.filter((user) => 
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filteredUsers.length > 0) {
        dispatch(setOtherUsers(filteredUsers));
      } else {
        toast.error('User not found!');
      }
    }
  };

  useEffect(() => {
    filterUsers(search);
  }, [search]);

  return (
    <div className="bg-gray-800 h-full flex flex-col">
      <div className="sm:hidden flex justify-between items-center p-4">
        <h1 className="text-white text-xl font-bold">ChatPro</h1>
        <button 
        onClick={() => {
          setIsOpen(!isOpen)
          setOpenSearch(true);
      }} 
        className="text-white">
          {isOpen ? <X /> : <Menu />}
        </button>
      </div>
      <div className={`flex-grow ${isOpen ? 'block' : 'hidden'} sm:block`}>
        <div className="p-4">
          <div className="flex items-center space-x-3 mb-4">
            {authUser?.profilePhoto ? (
              <img
                src={authUser.profilePhoto}
                alt={authUser.fullName}
                className="w-12 h-12 rounded-full object-cover"
              />
            ) : (
              <User className="w-12 h-12 text-gray-400 bg-gray-700 rounded-full p-2" />
            )}
            <div>
              <p className="font-medium text-white">{authUser?.fullName}</p>
              <p className="text-sm text-gray-400">{authUser?.email}</p>
            </div>
          </div>
          <form 
          onSubmit={searchSubmitHandler} 
          className="flex items-center">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="text"
              placeholder="Search..."
            />
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <Search className="w-5 h-5" />
            </button>
          </form>
        </div>

        <div className="flex-grow overflow-y-auto sm:h-[calc(100%-14rem)]">
          <OtherUsers setOpenSearch={setOpenSearch} setIsOpen={setIsOpen}/>
        </div>
      </div>
      <div className="p-4 mt-auto">
        <button
          onClick={logoutHandler}
          className="w-full flex items-center justify-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          <LogOut className="w-5 h-5 mr-2" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
